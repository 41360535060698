.img-container {
    position: relative;
}

.dark-shadow{
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 1));
}
.svg-square-1 {
    border-radius: 25px;
    /* padding: 20px; */
    width: 50%;
    height: 40%;
    position: absolute;
    z-index:-999;
}
.svg-square-2 {
    border-radius: 25px;
    /* padding: 20px; */
    width: 50%;
    height: 40%;
    position: absolute;
    z-index:-999;
    right: 0;
    bottom: 20%;
}
.svg-square-3 {
    border-radius: 25px;
    /* padding: 20px; */
    width: 50%;
    height: 40%;
    position: absolute;
    z-index:-999;
    top: 25%;
    right: 0;
}
.svg-square-4 {
    border-radius: 25px;
    /* padding: 20px; */
    width: 50%;
    height: 40%;
    position: absolute;
    z-index:-999;
    bottom: 10%;
}


