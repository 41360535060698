.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #71982d;
  background-image: url("../../../../assets/img/hero.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-background {
  background-image: url(https://websitedemos.net/tech-startup-02/wp-content/uploads/sites/708/2020/09/tech-startup-hero-bg-color-shape.svg);
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  /* transition: background .3s,border-radius .3s,opacity .3s; */
  /* height: 100%;
  width: 100%;
  top: 0;
  left: 0; */
  /* position: absolute; */
}

/* Hide background image on smaller screens */
@media all and (max-width: 992px) {
  .App-header {
    background-image: none !important;
  }
}

.hero-form {
  background-color: #ffffff;
  border-radius: 0px 5px 5px 5px;
}
.nav-link:not(.active) {
  background-color: #ffffff !important;
  opacity: 0.5;
} 

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
